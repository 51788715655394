import React, { useEffect } from 'react';
import { logInSSO, MY_HOST, DOMAIN } from '../../ts/Common';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from "react-router-dom";
import { CookieSetOptions } from 'universal-cookie';

interface AuthRedirectProps {
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
    setSignInProgress: React.Dispatch<React.SetStateAction<boolean>>;
    removeCookie: (name: "user64", options: CookieSetOptions) => void;
    setUser: React.Dispatch<React.SetStateAction<string>>;
    setShowLoginError: React.Dispatch<React.SetStateAction<string>>;
    setLoginErrorText: React.Dispatch<React.SetStateAction<string>>;
}

export default function AuthRedirect(props: AuthRedirectProps) {
    const { setIsLoggedIn, setSignInProgress, removeCookie, setUser, setShowLoginError, setLoginErrorText } = props;
    const navigate = useNavigate();
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    useEffect(() => {
        if (activeAccount != null) {
            console.log('Auth Redirect: Logging in via Licensing');
            Login();
        } else {
            console.log('Auth Redirect: Unable to detect Active Account');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeAccount])

    const Login = async function () {
        removeCookie('user64', { path: '/', domain: DOMAIN });
        setSignInProgress(true);
        let tokenToSend = activeAccount?.idToken ?? '';
        try {
            let loginResponse = await logInSSO(`${MY_HOST}/api/Account/LogInLinkSSO`, tokenToSend)
            if (!loginResponse.ok) {
                setShowLoginError("block");
                setLoginErrorText("Error logging in, please try again")
                navigate('/login');
                return;
            }
            let loginJson = await loginResponse.json();
            setShowLoginError("none");
            setUser(loginJson['email']);
            setIsLoggedIn(true);
            navigate('/matchmaking');
        } catch (err) {
            console.log(err);
            setShowLoginError("block");
            setLoginErrorText("Error logging in, please try again")
            navigate('/login');
        }
        setSignInProgress(false);
    }

    return (
        <></>
    );
};
