import { HttpResponse } from 'msw';
import { Machine } from './Connections';
import { Cargo } from './Cargo';

export const MY_HOST = process.env.REACT_APP_MY_HOST || "https://spectre-licensing.com"
export const DOMAIN = process.env.REACT_APP_DOMAIN || '.spectre-licensing.com'

async function fetchRequest(url: string, method: string, body: string | null, headers: HeadersInit = { 'Content-Type': 'application/json' }) {
    return fetch(url, {
        method: method,
        body: body,
        credentials: 'include',
        headers: headers
    })
}

export async function logIntoHost(credentials: string, spectreLoginUrl: string): Promise<HttpResponse> {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    const response = await fetchRequest(spectreLoginUrl, 'POST', `Credentials=${credentials}`, headers);
    return response;
}

export async function logInSSO(spectreLoginUrl: string, tokenToSend: string): Promise<HttpResponse> {
    const body = JSON.stringify({
        token: tokenToSend
    });
    const response = await fetchRequest(spectreLoginUrl, 'POST', body);
    return response;
}

export async function logoutSSO(spectreLogoutUrl: string): Promise<HttpResponse> {
    const response = await fetchRequest(spectreLogoutUrl, 'GET', null);
    return response;
}

export function removeUser64Cookie() {
    document.cookie = "user64=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export async function getCustomFields(): Promise<any> {
    let headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
    return fetchRequest(`${MY_HOST}/api/CustomFields`, 'GET', null, headers)
        .then(data => data.json())
        .then((data) => {
            return data;
        })
}

export async function handleTextChange(elementValue: any, settingPath: string, machine: Machine) {
    elementValue = elementValue.replace(/ /g, '%5C%20');
    elementValue = elementValue.replace("+", '%5C+');

    await machine.connection.get(`SetSetting?Path=/Settings/User/${settingPath}&Value=${elementValue}`)
        .catch((err: any) => {
            alert('Machine Settings Not Saved, Please Check Machine is On')
        })
}

export async function retrieveCertification(machine: Machine) {
    let command: any = {
        "Requests": {
            "GetCertification": {
            }
        }
    }
    return await machine.connection.post(command)
        .then((response: any) => {
            return response;
        })
}

export function getCookie(cname: any) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export async function CheckLoginStatus() {
    let response = await fetchRequest(`${MY_HOST}/api/Account/CheckLoginStatus`, 'POST', null)
    return response;
}

export function hasCertifiableDimensions(scannedDimension: any, unitMeasurement: string, minDimension: number, maxDimension: number) {
    const units: any = {
        'millimeters': 0,
        'centimeters': 1,
        'meters': 2,
        'inches': 3,
        'feet': 4,
        'millimeter': 0,
        'centimeter': 1,
        'meter': 2,
        'inch': 3,
        'foot': 4,
        'mm': 0,
        'cm': 1,
        'm': 2,
        'in': 3,
        'ft': 4,
    }

    if (unitMeasurement.toLowerCase() !== "inches" || unitMeasurement.toLowerCase() !== "inch" || unitMeasurement.toLowerCase() !== "in") {
        scannedDimension = convertUnits(scannedDimension, units[unitMeasurement], 3)
    }

    if (scannedDimension >= minDimension && scannedDimension <= maxDimension) {
        return true;
    } else {
        return false;
    }
}

export function convertUnits(value: number, currentUnit: number, desiredUnit: number): number {
    // Conversion matrix for millimeters, centimeters, meters, inches, and feet
    const conversionMatrix: number[][] = [
        //       mm    cm    m      in      ft
        /* mm */[1, 0.1, 0.001, 0.0394, 0.00328084],
        /* cm */[10, 1, 0.01, 0.3937, 0.0328084],
        /* m  */[1000, 100, 1, 39.3701, 3.28084],
        /* in */[25.4, 2.54, 0.0254, 1, 0.0833],
        /* ft */[304.8, 30.48, 0.3048, 12, 1]
    ];

    // Perform the conversion using the conversion matrix
    const conversionFactor: number = conversionMatrix[currentUnit][desiredUnit];
    const convertedValue: number = value * conversionFactor;

    return convertedValue;
}

export function splitAndFormatString(input: string) {
    // Use a regular expression to find the points where a capital letter follows a lowercase letter
    let result = input.replace(/([a-z])([A-Z])/g, '$1 $2');

    // Convert the entire string to lowercase
    result = result.toLowerCase();

    // Capitalize the first word
    result = result.charAt(0).toUpperCase() + result.slice(1);

    return result;
}

// Helper function for comparing version strings (adapted from: https://gist.github.com/TheDistantSea/8021359)
export function versionCompare(v1: any, v2: any, options: any) {
    let lexicographical = (options && options.lexicographical);
    let zeroExtend = (options && options.zeroExtend);
    let v1parts = v1.split('.');
    let v2parts = v2.split('.');

    function isValidPart(x: any) {
        return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }

    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
        return NaN;
    }

    if (zeroExtend) {
        while (v1parts.length < v2parts.length) v1parts.push("0");
        while (v2parts.length < v1parts.length) v2parts.push("0");
    }

    if (!lexicographical) {
        v1parts = v1parts.map(Number);
        v2parts = v2parts.map(Number);
    }

    for (let i = 0; i < v1parts.length; ++i) {
        if (v2parts.length === i) {
            return 1;
        }

        if (v1parts[i] === v2parts[i]) {
            continue;
        }
        else if (v1parts[i] > v2parts[i]) {
            return 1;
        }
        else {
            return -1;
        }
    }

    if (v1parts.length !== v2parts.length) {
        return -1;
    }

    return 0;
};

export function getShortUnitString(unit: string): string {
    const unitMap: Record<string, string> = {
        // dimensions
        "inches": "in",
        "centimeters": "cm",
        "millimeters": "mm",
        "meters": "m",
        "feet": "ft",
        // volumes
        "cubic millimeters": "mm³",
        "cubic meters": "m³",
        "cubic inches": "in³",
        "cubic feet": "ft³",
        "cubic centimeters": "cm³",
        // densities
        "lb/cubic feet": "lb/ft³",
        "lb/cubic inch": "lb/in³",
        "lb/cubic meter": "lb/m³",
        "lb/cubic centimeter": "lb/cm³",
        "lb/cubic millimeter": "lb/mm³",
        "kg/cubic feet": "kg/ft³",
        "kg/cubic inch": "kg/in³",
        "kg/cubic meter": "kg/m³",
        "kg/cubic centimeter": "kg/cm³",
        "kg/cubic millimeter": "kg/mm³",
        "g/cubic foot": "g/ft³",
        "g/cubic inch": "g/in³",
        "g/cubic meter": "g/m³",
        "g/cubic centimeter": "g/cm³",
        "g/cubic millimeter": "g/mm³",
    };

    return unitMap[unit] || "";
}

export function parseBoolean(value: string | boolean): boolean {
    if (typeof value === 'boolean') {
        return value;
    } else if (value.toLowerCase() === 'true') {
        return true;
    } else if (value.toLowerCase() === 'false') {
        return false;
    }
    return false;
}

export function parseListDirectoryResponse(response: any, machine: Machine) {
    let scanArray = response['Responses']['ListDirectory']['Directory'];

    let parsedDirectory = [];

    for (let i = 0; i < scanArray.length; i++) {
        let lastScanCustomFields;
        if (scanArray[i]['Info'] && 'CustomFields' in scanArray[i]['Info']) {
            lastScanCustomFields = scanArray[i]['Info']['CustomFields'];
            for (let j = 0; j < lastScanCustomFields['Field'].length; j++) {
                lastScanCustomFields['Field'][j]['FieldType'] = Number(lastScanCustomFields['Field'][j]['FieldType']);
                lastScanCustomFields['Field'][j]['Id'] = Number(lastScanCustomFields['Field'][j]['Id']);
                lastScanCustomFields['Field'][j]['Required'] = Boolean(lastScanCustomFields['Field'][j]['Required']);
                lastScanCustomFields['Field'][j]['Ready'] = Boolean(lastScanCustomFields['Field'][j]['Ready']);
            }
        } else {
            lastScanCustomFields = {};
        }

        let cargo = new Cargo(machine.certification, { "CustomFields": lastScanCustomFields });
        cargo.parseListDirectoryResponse(scanArray[i]);
        parsedDirectory.push(cargo);
    }
    return parsedDirectory;
}