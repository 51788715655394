import { useEffect, useState } from 'react';
import './App.css';
import {
  Routes,
  Route,
  Navigate,
  useLocation, 
  useNavigate
} from 'react-router-dom';
import Matchmaking from './pages/Matchmaking/Matchmaking';
import Login from './pages/Login/Login'
import Control from './pages/Control/Control';
import { ThemeProvider } from '@mui/material'
import { Navbar } from './components/Navbar';
import { CssBaseline } from "@mui/material";
import { getCookie, logIntoHost, CheckLoginStatus } from './ts/Common';
import { useCookies } from 'react-cookie';
import SpectreTheme from './SpectreTheme';
import { Machine } from './ts/Connections';
import { MachineEvent } from './ts/MachineEvent';
import AuthRedirect from './pages/AuthRedirect/AuthRedirect';
import AuthRedirectLogOut from './pages/AuthRedirect/AuthRedirectLogOut';
import LoginSSO from './pages/AuthRedirect/LoginSSO';
import LogoutSSO from './pages/AuthRedirect/LogoutSSO';
import { HttpResponse } from 'msw';
import { useMsal } from '@azure/msal-react';

const MY_HOST = process.env.REACT_APP_MY_HOST || "https://spectre-licensing.com"
let didInit = false;

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [selectedMachine, setSelectedMachine] = useState<any>(false);
  const [signInProgress, setSignInProgress] = useState<boolean>(false);
  const [cookies, , removeCookie] = useCookies(['user64']);
  const [cookieSpinner, setCookieSpinner] = useState<boolean>(false);
  const [user, setUser] = useState<string>("");
  const [showMachineSettings, setShowMachineSettings] = useState<boolean>(false);
  const [showCalibration, setShowCalibration] = useState<boolean>(false);
  const [showSupport, setShowSupport] = useState<boolean>(false);
  const [showCertification, setShowCertification] = useState<boolean>(false);
  const [onlineMachines, setOnlineMachines] = useState<Array<Machine>>([]);
  const [machineEvents, setMachineEvents] = useState<MachineEvent[]>([]);
  const [localMachines, setLocalMachines] = useState<Array<Machine>>([]);
  const [showLoginError, setShowLoginError] = useState<string>("none")
  const [loginErrorText, setLoginErrorText] = useState<string>("");
  const location = useLocation();
  const navigate = useNavigate();
  const { accounts } = useMsal();
  const activeAccount = accounts.find((account) => account.homeAccountId === accounts[0].homeAccountId);

  useEffect(() => {
    if (location.pathname === '/authRedirectLogOut' || location.pathname === '/authRedirect') {
        return
      }
    if (!didInit) {
      didInit = true;
      CheckLoginStatus().then((response: HttpResponse) => {
        if (response.ok) {
          setIsLoggedIn(true);
          return response.json();
        } else {
          console.log('Check Login Status: Not logged in');
          return;
        }
      })
        .then((responseJson: { email: string, ssoEnabled: boolean }) => {
          if (!responseJson) {
            return;
          }
          if (responseJson && responseJson.ssoEnabled) {
            setUser(responseJson.email);
            if (!activeAccount) {
              navigate('/loginsso')
            }
          }
        setUser(responseJson.email);
      })
    }
  }, [location.pathname])

  useEffect(() => {
    const credentials = getCookie("user64")
    if (credentials) {
      setSignInProgress(true);
      setCookieSpinner(true);
      logIntoHost(credentials, `${MY_HOST}/api/Account/LoginPWA`)
        .then((response: any) => {
          if (response.ok) {
            setIsLoggedIn(true);
            setSignInProgress(false);
            setCookieSpinner(false);
            setUser(atob(credentials).split(":")[0])
          } else {
            console.log('cookie check failed, re enter password and login again')
            setSignInProgress(false);
            setCookieSpinner(false);
          }
        })
        .catch((err: any) => {
          console.log('Error logging in')
          setSignInProgress(false);
          setCookieSpinner(false);
        })
    }
  }, [])

  useEffect(() => {
    selectedMachine ? document.title = `Spectre Link - ${selectedMachine.machineName}` : document.title = 'Spectre Link'
  }, [selectedMachine]);

  if (!isLoggedIn) {
    return (
      <div>
        <ThemeProvider theme={SpectreTheme}>
          <Routes>
            <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} signInProgress={signInProgress} setSignInProgress={setSignInProgress} cookieSpinner={cookieSpinner} setUser={setUser} removeCookie={removeCookie} showLoginError={showLoginError} setShowLoginError={setShowLoginError} loginErrorText={loginErrorText} setLoginErrorText={setLoginErrorText} />} />
            <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} signInProgress={signInProgress} setSignInProgress={setSignInProgress} cookieSpinner={cookieSpinner} setUser={setUser} removeCookie={removeCookie} showLoginError={showLoginError} setShowLoginError={setShowLoginError} loginErrorText={loginErrorText} setLoginErrorText={setLoginErrorText} />} />
            <Route path="/authRedirect" element={<AuthRedirect setIsLoggedIn={setIsLoggedIn} setSignInProgress={setSignInProgress} setUser={setUser} removeCookie={removeCookie} setShowLoginError={setShowLoginError} setLoginErrorText={setLoginErrorText} />} />
            <Route path="/authRedirectLogOut" element={<AuthRedirectLogOut setIsLoggedIn={setIsLoggedIn} setUser={setUser} removeCookie={removeCookie} setShowLoginError={setShowLoginError} setLoginErrorText={setLoginErrorText} />} />
            <Route path="/LoginSSO" element={<LoginSSO/>} />
            <Route path="/LogoutSSO" element={<LogoutSSO/>} />
            <Route path="login" element={isLoggedIn ? <Navigate to="/matchmaking" /> : <Login setIsLoggedIn={setIsLoggedIn} signInProgress={signInProgress} setSignInProgress={setSignInProgress} cookieSpinner={cookieSpinner} setUser={setUser} removeCookie={removeCookie} showLoginError={showLoginError} setShowLoginError={setShowLoginError} loginErrorText={loginErrorText} setLoginErrorText={setLoginErrorText} />} />
            <Route path="/" element={isLoggedIn ? <Navigate to="/matchmaking" /> : <Login setIsLoggedIn={setIsLoggedIn} signInProgress={signInProgress} setSignInProgress={setSignInProgress} cookieSpinner={cookieSpinner} setUser={setUser} removeCookie={removeCookie} showLoginError={showLoginError} setShowLoginError={setShowLoginError} loginErrorText={loginErrorText} setLoginErrorText={setLoginErrorText} />} />
          </Routes>
        </ThemeProvider>
      </div>)
  }

  else {
    return (
      <ThemeProvider theme={SpectreTheme}>
        <CssBaseline />
        <Navbar setIsLoggedIn={setIsLoggedIn} user={user} removeCookie={removeCookie} setShowMachineSettings={setShowMachineSettings} setShowCalibration={setShowCalibration}
          setShowSupport={setShowSupport} setShowCertification={setShowCertification} selectedMachine={selectedMachine} setSelectedMachine={setSelectedMachine} onlineMachines={onlineMachines} setMachineEvents={setMachineEvents}/>
        <div className="App">
          <Routes>
            <Route path="login" element={isLoggedIn ? <Navigate to="/matchmaking" /> : <Login showLoginError={showLoginError} setShowLoginError={setShowLoginError} loginErrorText={loginErrorText} setLoginErrorText={setLoginErrorText} />} />
            <Route path="/LoginSSO" element={<LoginSSO />} />
            <Route path="/" element={isLoggedIn ? <Navigate to="/matchmaking" /> : <Login showLoginError={showLoginError} setShowLoginError={setShowLoginError} loginErrorText={loginErrorText} setLoginErrorText={setLoginErrorText} />} />
            <Route path='/matchmaking/*' element={< Matchmaking setIsLoggedIn={setIsLoggedIn} setSelectedMachine={setSelectedMachine} cookies={cookies} removeCookie={removeCookie} onlineMachines={onlineMachines} setOnlineMachines={setOnlineMachines} localMachines={localMachines} setLocalMachines={setLocalMachines}
              setMachineEvents={setMachineEvents}></Matchmaking>}></Route>
            <Route path='/matchmaking/control/:id' element={< Control machine={selectedMachine} showMachineSettings={showMachineSettings} setShowMachineSettings={setShowMachineSettings} showCalibration={showCalibration} setShowCalibration={setShowCalibration}
              showSupport={showSupport} setShowSupport={setShowSupport} showCertification={showCertification} setShowCertification={setShowCertification} onlineMachines={onlineMachines} setSelectedMachine={setSelectedMachine} machineEvents={machineEvents} setMachineEvents={setMachineEvents} />}></Route>
            <Route path="/authRedirect" element={<AuthRedirect setIsLoggedIn={setIsLoggedIn} setSignInProgress={setSignInProgress} setUser={setUser} removeCookie={removeCookie} setShowLoginError={setShowLoginError} setLoginErrorText={setLoginErrorText} />} />
            <Route path="/authRedirectLogOut" element={<AuthRedirectLogOut setIsLoggedIn={setIsLoggedIn} setUser={setUser} removeCookie={removeCookie} setShowLoginError={setShowLoginError} setLoginErrorText={setLoginErrorText} />} />
          </Routes>
        </div>
      </ThemeProvider>
    );
  }
}

export default App;