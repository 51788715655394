import { Grid, Button } from "@mui/material";
import "./RemoteControlPassive.css";

export default function RemoteControlPassive() {
    return (
        <Grid item container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            md={12}>
            <Grid item md={11} xs={11} pt={2}>
                <Button className="Scan-Button passive" fullWidth disabled={true}>
                    Awaiting New Scans
                    <div className="circle"></div>
                </Button>
            </Grid>
        </Grid>
    );
}