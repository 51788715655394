// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.passive {
    background-color: #ffcc99;
    /* Orange color */
    color: white;
    position: relative;
    overflow: hidden;
    font-size: 1.2em;
    border: none;
    border-radius: 4px;
    cursor: not-allowed;
    padding: 12px 24px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.passive::before,
.passive::after,
.passive .circle {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    border: 2px solid rgba(255, 255, 255, 0.6);
    /* White circles */
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: ripple 4s infinite;
}

.passive::before {
    animation-delay: 0s;
}

.passive::after {
    animation-delay: 0.5s;
}

.passive .circle {
    animation-delay: 1s;
}

@keyframes ripple {
    0% {
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        width: 400%;
        height: 800%;
        opacity: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Control/Inputs/inputComponents/RemoteControlPassive.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,0CAA0C;AAC9C;;AAEA;;;IAGI,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,QAAQ;IACR,SAAS;IACT,0CAA0C;IAC1C,kBAAkB;IAClB,kBAAkB;IAClB,gCAAgC;IAChC,6BAA6B;AACjC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI;QACI,QAAQ;QACR,SAAS;QACT,UAAU;IACd;;IAEA;QACI,WAAW;QACX,YAAY;QACZ,UAAU;IACd;AACJ","sourcesContent":[".passive {\n    background-color: #ffcc99;\n    /* Orange color */\n    color: white;\n    position: relative;\n    overflow: hidden;\n    font-size: 1.2em;\n    border: none;\n    border-radius: 4px;\n    cursor: not-allowed;\n    padding: 12px 24px;\n    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.passive::before,\n.passive::after,\n.passive .circle {\n    content: '';\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    width: 0;\n    height: 0;\n    border: 2px solid rgba(255, 255, 255, 0.6);\n    /* White circles */\n    border-radius: 50%;\n    transform: translate(-50%, -50%);\n    animation: ripple 4s infinite;\n}\n\n.passive::before {\n    animation-delay: 0s;\n}\n\n.passive::after {\n    animation-delay: 0.5s;\n}\n\n.passive .circle {\n    animation-delay: 1s;\n}\n\n@keyframes ripple {\n    0% {\n        width: 0;\n        height: 0;\n        opacity: 1;\n    }\n\n    100% {\n        width: 400%;\n        height: 800%;\n        opacity: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
