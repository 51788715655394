import { List } from '@mui/material'
import PictureScanEvent from './scanHistoryComponents/PictureScanEvent';
import React, { useEffect, useMemo } from 'react'
import ScanFailure from './scanHistoryComponents/ScanFailure';
import TextEvent from './scanHistoryComponents/TextEvent';
import { Machine } from '../../../ts/Connections';
import { TextEventProps, MachineEvent } from '../../../ts/MachineEvent';
import { Cargo } from '../../../ts/Cargo';

interface ScanHistoryProps {
    selectedScanIndex: number;
    setSelectedScanIndex: (index: number) => void;
    cargoResponses: Cargo[];
    machineEvents: MachineEvent[];
    setOpenScanDetails: (open: boolean) => void;
    machine: Machine;
}

function ScanHistory(props: ScanHistoryProps) {
    const { selectedScanIndex, setSelectedScanIndex, cargoResponses, machineEvents, setOpenScanDetails, machine } = props
    
    // Create a combined timeline of machine events and scan events (and sort by timestamp)
    const timelineEvents = useMemo(() => {
        return [...machineEvents, ...cargoResponses].sort((a, b) =>  b.timeStamp.getTime() - a.timeStamp.getTime());
    }, [machineEvents, cargoResponses]);

    const handleListItemClick = (
        _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedScanIndex(index);
    };

    const openDetailsModal = function () {
        console.log('Scan Details Modal Disabled on Medium view')
    }

    return (
        <List>
            {
                timelineEvents.map((historyEvent, index) => {
                    if (historyEvent instanceof Cargo) {
                        if (historyEvent.dimensionCode === "0") {
                            return (
                                <PictureScanEvent
                                    key={index} 
                                    scanFailure={false}
                                    cargoResponse={historyEvent}
                                    cargoResponseIndex={index} 
                                    index={index} 
                                    handleListItemClick={handleListItemClick}
                                    selectedScanIndex={selectedScanIndex}
                                    setOpenScanDetails={setOpenScanDetails}
                                    openDetailsModal={openDetailsModal}
                                    machine={machine} 
                                />
                            )
                        } else {
                            return (
                                <ScanFailure key={index} cargoResponse={historyEvent} handleListItemClick={handleListItemClick}
                                    cargoResponseIndex={index} selectedScanIndex={selectedScanIndex} setOpenScanDetails={setOpenScanDetails}></ScanFailure>
                            )
                        }
                    }
                    else if (historyEvent instanceof TextEventProps) {
                        return <TextEvent {...historyEvent} />
                    }
                    else {
                        return undefined // Unknown history event type
                    }
                })
            }
        </List>
    )
}

export default React.memo(ScanHistory);