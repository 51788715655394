import { useEffect } from "react";
import { useMsal } from '@azure/msal-react';

export default function LogoutSSO () {
    const { instance } = useMsal();

    useEffect(() => {
        instance.logoutRedirect().catch((error) => console.log(error));
    })

    return (
        <></>
    )
}
