import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { logoutSSO, MY_HOST, DOMAIN } from '../../ts/Common';
import { CookieSetOptions } from 'universal-cookie';

interface AuthRedirectLogOutProps {
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
    removeCookie: (name: "user64", options: CookieSetOptions) => void;
    setUser: React.Dispatch<React.SetStateAction<string>>
    setShowLoginError: React.Dispatch<React.SetStateAction<string>>
    setLoginErrorText: React.Dispatch<React.SetStateAction<string>>
}

export default function AuthRedirectLogOut(props: AuthRedirectLogOutProps) {
    const { setIsLoggedIn, removeCookie, setUser, setShowLoginError, setLoginErrorText } = props;
    const navigate = useNavigate();

    useEffect(() => {
        Logout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const Logout = async function () {
        removeCookie('user64', { path: '/', domain: DOMAIN })

        try {
            let logoutResponse = await logoutSSO(`${MY_HOST}/api/Account/LogOutLinkSSO`)
            if (!logoutResponse.ok) {
                navigate('/login');
                console.log("AuthRedirectLogout: SSO Logout failed")
                return;
            }
            console.log('AuthRedrectLogout: Successful SSO Logout');
            setShowLoginError("none");
            setUser('')
            setIsLoggedIn(false);
            navigate('/login');
        } catch (err) {
            console.log(err);
            setShowLoginError("block");
            setLoginErrorText("Error logging out, please try again");
        }
    }

    return (
        <></>
    );
};