import { useEffect } from "react";
import { loginRequest } from "../../authConfig";
import { useMsal } from '@azure/msal-react';

export default function LoginSSO () {
    const { instance } = useMsal();

    useEffect(() => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    })

    return (
        <></>
    )
}